import React from 'react'
import styled from 'styled-components'
import {MDXProvider} from '@mdx-js/react'
import {Col, Row} from 'react-grid-system'
import ContainerWrapper from '../../../components/ContainerWrapper'
import CategoryArticles from "../../../components/CategoryArticles";
import teaserHowtoGuidesBackgound from '../../../images/bg-teaser-howto-guides.svg'
import Seo from "../../../components/SEO";

const Style = styled.div`


  .summary-row {
    margin-top: 80px
  }

  .summary-row + .summary-row {
    margin-top: 24px;
    padding-top: 24px;
    border-top: solid 1px #d2d2d2;
  }

  h2.category {
    font-weight: 300;
    border-bottom: solid 1px #d2d2d2;
    padding-bottom: 1rem;

    a, a:active, a:visited, a:hover {
      font-size: 16px;
      margin-left: 24px;
      color: #006cae;

    }

  }

  .summary-list {
    list-style-type: none;
    margin-bottom: 0;

    li {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 3px;

      > * + * {
        padding-left: 6px;
      }

      a {
        color: #006cae;
      }

      > div:first-child {
        height: 20px;
        color: #006cae;
      }

      svg {
        margin-top: 4px;
        width: 16px;
        height: 16px;
      }

      &:last-child {
        a, > div:first-child {
          color: #868686;
        }
      }
    }
  }

  .abstract {
    p:first-of-type {
      color: #787878;
      font-size: 20px;
    }

  }

  .summary {
    color: #787878;
  }

`;

const DiscoverComponentsPage = ({children, pageContext, location}) => {
  const slug = location.pathname.substr(1 + process.env.SITE_PATH_PREFIX.length);
  const {
    breadcrumb: {crumbs},
  } = pageContext


  const {frontmatter} = pageContext;

  return (
    <ContainerWrapper crumbs={crumbs} withFeedback>
      <Seo
        title={"CoreMedia Components and Concepts"}
        description={"Description of main components and architecture of CoreMedia Experience Platform"}
      />
      <Style>
        <Row>
          <Col xl={12}>
            <h1>CoreMedia Components and Concepts</h1>
            <div>
              <p>
                CoreMedia Content Cloud is a distributed system with several components that work together to deliver a flawless experience.
                Here, you will find a description of the overall architecture, other important concepts and the different components.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <MDXProvider>
              <div className={'article'}>{children}</div>
            </MDXProvider>
            <>
          <CategoryArticles slug={slug} frontmatter={frontmatter} size={4} background={teaserHowtoGuidesBackgound} type={"concept"} inpage={false}/>
            </>
          </Col>
        </Row>
      </Style>
    </ContainerWrapper>
  )
}

export default DiscoverComponentsPage;
